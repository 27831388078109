import React from 'react';

import './index.scss';
import { CTOCard } from '../../../components/CTOCard';
import hero_image from '../../../assets/hero_image.png'


export const HeroSection = () => {
    return <div className='row hero-section'>
        <div className='col-12 col-md-7'>
            <h1 className='hero-title'>
                Never be short on <span className='colored'>data</span> again 📊
            </h1>
            <h2 className='hero-subtitle mt-4'>
                Generate synthetic, GDPR-compliant data that is undistinguishable 
                from real data, so you can train your AI models faster and better 
            </h2>
            <div className='row mt-5'>
                <div className='col-12 col-md-8'>
                    <CTOCard 
                        category='NEWS'
                        title='Synthex, our new Python library 🐍'
                        text="Synthex is our Python library that lets you generate synthetic data quickly 
                        and effortlessly. Give it a try!"
                        buttonText='Try Synthex now'
                        onClick={() => window.open('https://github.com/tanaos/llama3')}
                    />
                </div>
            </div>
        </div>
        <div className='col-5 m-0 p-0 ps-5 mt-4 desktop-only'>
            <img src={ hero_image } alt='hero' />
        </div>
    </div>
};

export default HeroSection;