import React from 'react';

import './index.scss';


export const ValuesSection = () => {
    return <div className='values-section'>
        <h2 className='mt-5'>Our values</h2>
        <div className='row mt-5'>
            <div className='col-12 col-md-1'>
                <span className='value-number'>1</span>
            </div>
            <div className='col-12 col-md-9'>
                <h3>Democratization</h3>
                <p>
                    We believe that AI should be a democratic technology, accessible to as many people as 
                    possible. The main objective of our work is to lower the barriers to entry to the field,
                    and to make the development and use of Artificial Intelligence models approachable by
                    individuals and organizations of any size and budget.
                </p>
            </div>
        </div>
        <div className='row mt-4 mt-md-5'>
            <div className='col-12 col-md-1'>
                <span className='value-number'>2</span>
            </div>
            <div className='col-12 col-md-9'>
                <h3>Pivacy and safety</h3>
                <p>
                    We know that our users are concerned with data privacy and safety, and we take this
                    responsibility seriously. That's why the synthetic data generated by our tools is 100% 
                    anonymized, private and GDPR-compliant. We don't retain the data you generate and — 
                    unlike most AI companies — we don't use it to train our own models.
                </p>
            </div>
        </div>
        <div className='row mt-4 mt-md-5'>
            <div className='col-12 col-md-1'>
                <span className='value-number'>3</span>
            </div>
            <div className='col-12 col-md-9'>
                <h3>Reliability</h3>
                <p>
                    We pride ourselves on being a reliable partner for all our users and customers. User and
                    customer satisfaction is our top priority, and they can count on us to deliver on our
                    promises. Are you facing issues with any one of our products? Get in touch with us,
                    we will start working on it right away.
                </p>
            </div>
        </div>
        <div className='row mt-4 mt-md-5 mb-5'>
            <div className='col-12 col-md-1'>
                <span className='value-number'>4</span>
            </div>
            <div className='col-12 col-md-9'>
                <h3>Innovation</h3>
                <p>
                    The AI field is changing constantly, and we don't want to be passive observers. We want
                    to contribute to the State of the Art by developing cutting-edge technology and producing
                    innovative research. We don't want to be just a research company or just an engineering
                    company. We want to be both.
                </p>
            </div>
        </div>
    </div>
};

export default ValuesSection;