import React from 'react';

import './index.scss';
import { Navbar } from '../../components/Navbar';
import { HeroSection } from './HeroSection';
import { VisionAndMissionSection } from './VisionAndMissionSection';
import { ValuesSection } from './ValuesSection';
import { GetStartedBar } from '../GetStartedBar';
import { Footer } from '../../components/Footer';


export const CompanyPageLayout = () => {
    return <div className='company-page-layout'>
        <Navbar />
        <HeroSection />
        <VisionAndMissionSection />
        <hr />
        <ValuesSection />
        <GetStartedBar />
        <Footer />
    </div>
};

export default CompanyPageLayout;