import React from 'react';

import './index.scss';


export const HeroSection = () => {
    return <div className='company-page-hero-section'>
        <div className='row'>
            <div className='col-12 col-md-8'>
                <h1 className='hero-title'>
                    Enabling a <span className='colored'>fast</span>
                    , <span className='colored'>safe</span> and <span className='colored'>democratic</span> future
                    for AI
                </h1>
                <h2 className='hero-subtitle mt-5'>
                    At Tanaos we develop tools for synthetic data generation that allow anybody to train 
                    their own AI models, even if they do not have the right real-world data
                </h2>
                <button className='btn btn-primary mt-5 me-3 me-md-5 mb-4 mb-md-0'>See our products</button>
                <button className='btn btn-light mt-5 mb-4 mb-md-0'>Read the docs</button>
            </div>
        </div>
    </div>
};

export default HeroSection;
