import React from 'react';

import './index.scss';
import { Navbar } from '../../components/Navbar';
import { HeroSection } from './HeroSection';
import { WhyTanaosSection } from './WhyTanaosSection';
import { HowToUseSection } from './HowToUseSection';
import { CaseStudiesSection } from './CaseStudiesSection';
import { GetStartedBar } from '../GetStartedBar';
import { Footer } from '../../components/Footer';


export const HomePageLayout = () => {

    return <div className='homepage-layout'>
        <Navbar />
        <HeroSection />
        <WhyTanaosSection />
        <HowToUseSection />
        <hr />
        <CaseStudiesSection />
        <GetStartedBar />
        <Footer />
    </div>
};