import React from 'react';

import './index.scss';


export const BlogThumbnail = ({ 
    img, date, title, description
}) => {
    return <div className='blog-thumbnail'>
        <img src={ img } alt='Tanaos' />
        <div className='mt-3 date'>
            <span>{ date }</span>
        </div>
        <div className='mt-3 title'>
            <h2 className='title'>{ title }</h2>
        </div>
        <div className='mt-3'>
            <span className='description'>{ description }</span>
        </div>
    </div>
};

export default BlogThumbnail;