import axios from 'axios';

import { DEV_API_BASE_URL, PROD_API_BASE_URL } from '../config';


export * from './blogArticles';


let base_url;

// Set global base URL.
if (process.env.NODE_ENV === 'production') {
    base_url = PROD_API_BASE_URL;
} else {
    base_url = DEV_API_BASE_URL;
}
axios.defaults.baseURL = base_url;

// Set global headers.
axios.defaults.headers = {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
};


export { axios };