import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { HomePage } from './pages/HomePage';
import { BlogPage } from './pages/BlogPage';
import { CompanyPage } from './pages/CompanyPage';
import { PricingPage } from './pages/PricingPage';
import { BlogArticlePage } from './pages/BlogArticlePage';
import { NotFoundPage } from './pages/NotFoundPage';


const AppRouter = () => {
    return <Routes>
        
        {/* Public routes */}
        <Route path='/' element={<HomePage />} />
        <Route path='/blog' element={<BlogPage />} />
        <Route path='/blog/:articleId' element={<BlogArticlePage />} />
        <Route path='/company' element={<CompanyPage />} />
        <Route path='/pricing' element={<PricingPage />} />
        {/* Fallback route for 404 Not Found */}
        <Route path='*' element={<NotFoundPage />} />
    </Routes>
}


const App = () => {

    return (
        <div className='pt-0 p-0 m-0'>
            <AppRouter />
        </div>
    );
}

export default App;
