import React from 'react';

import './index.scss';
import { PricingCard } from '../../../components/PricingCard';


export const PricingCardsSection = () => {
    return <div className='pricing-cards-section'>
        <h2>Our plans</h2>
        <div className='row mt-5 mb-5'>
            <div className='col-12 col-md-4'>
                <PricingCard 
                    name='Free'
                    buttonText='Use Synthex for free'
                    description='Ideal for people and organizations that are just starting out, or
                    that need a limited amount of data'
                    features={[
                        'Anonymized data', 'GDPR-compliant data', 'Python client', 
                        'Up to 50 datapoints per call', 'Limited use-cases', 'Lower tier AI model'
                    ]}
                    excludedFeatures={[
                        'API', 'Dedicated customer support'
                    ]}
                />
            </div>
            <div className='col-12 col-md-4 mt-5 mt-md-0'>
                <PricingCard 
                    name='Professional'
                    buttonText='Contact sales'
                    description='Perfect for people and organizations that need large datasets, but no
                    customer support'
                    features={[
                        'Anonymized data', 'GDPR-compliant data', 'Python client', 
                        'Up to 2 000 datapoints per call', 'Unlimited use-cases', 'Higher tier AI model',
                        'API'
                    ]}
                    excludedFeatures={[
                        'Dedicated customer support'
                    ]}
                />
            </div>
            <div className='col-12 col-md-4 mt-5 mt-md-0'>
                <PricingCard 
                    name='Enterprise'
                    buttonText='Contact sales'
                    description='Designed for people and organizations that need very large datasets and 
                    customer support'
                    features={[
                        'Anonymized data', 'GDPR-compliant data', 'Python client',
                        'Up to 10 000 datapoints per call', 'Unlimited use-cases', 'Higher tier AI model',
                        'API', 'Customer support'
                    ]}
                    excludedFeatures={[]}
                />
            </div>
        </div>
    </div>
};

export default PricingCardsSection;