import React from 'react';

import './index.scss';


export const GetStartedBar = () => {
    return <div className='get-started-section'>
        <div className='row'>
            <div className='col-12 col-md-6 pe-md-5'>
                <h2 className='mb-5'>Get started now</h2>
                <p className='mb-4 mb-md-5'>
                    Start training your AI models faster and better by laveraging the power of synthetic
                    data. Try Synthex, our new Python library for synthetic data generation 🧪📊
                </p>
                <button 
                    className='btn btn-primary me-4'
                    onClick={() => {}}
                >
                    Try Synthex now
                </button>
                <button 
                    className='btn btn-light'
                    onClick={() => {}}
                >
                    Read the docs
                </button>
            </div>
            <div className='col-12 col-md-6 ps-md-5 mt-5 mt-md-0'>
                <div className='row link' onClick={() => {}}>
                    <div className='col-1'>
                        <p><i className='bi bi-twitter-x'></i></p>
                    </div>
                    <div className='col-11'>
                        <p><b>Follow us on X ➔</b></p>
                        <p>
                            Follow us on X to stay updated with our latest developments and engage with
                            our community
                        </p>
                    </div>
                </div>
                <div className='row mt-4 link' onClick={() => {}}>
                    <div className='col-1'>
                        <p><i className='bi bi-files'></i></p>
                    </div>
                    <div className='col-11'>
                        <p><b>Read our docs ➔</b></p>
                        <p>
                            Find out how to install and use Synthex for all your data needs
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
};

export default GetStartedBar;