import React from 'react';

import './index.scss';
import { Navbar } from '../../components/Navbar';
import { HeroSection } from './HeroSection';
import { PricingCardsSection } from './PricingCardsSection';
import { GetStartedBar } from '../GetStartedBar';
import { Footer } from '../../components/Footer';


export const PricingPageLayout = () => {
    return <div>
        <Navbar />
        <HeroSection />
        <PricingCardsSection />
        <GetStartedBar />
        <Footer />
    </div>
};

export default PricingPageLayout;