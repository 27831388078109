import React from 'react';

import './index.scss';
import { Navbar } from '../../components/Navbar';
import { HeroSection } from './HeroSection';
import { ArticlesSection } from './ArticlesSection';
import { GetStartedBar } from '../GetStartedBar';
import { Footer } from '../../components/Footer';


export const BlogPageLayout = ({ thumbnails }) => {
    return <div>
        <Navbar />
        <HeroSection />
        <ArticlesSection />
        <GetStartedBar />
        <Footer />
    </div>
};

export default BlogPageLayout;