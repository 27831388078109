import React from 'react';

import './index.scss';


export const HeroSection = () => {
    return <div className='blog-page-hero-section'>
        <div className='row'>
            <div className='col-12 col-md-8'>
                <h1 className='hero-title'>See <span className='colored'>what's new</span> at Tanaos</h1>
                <h2 className='hero-subtitle mt-5'>
                    Learn more about synthetic data generation, generative AI and a bunch of other cool stuff
                    from the Tanaos team
                </h2>
            </div>
        </div>
        <button className='btn btn-primary mt-5'>Learn more about us</button>
    </div>
};

export default HeroSection;