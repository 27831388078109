import React from 'react';

import './index.scss';
import synthex from '../../../assets/synthex.png';


export const HowToUseSection = () => {
    return <div className='how-to-use-section mt-4 mb-5 mt-md-0 mb-md-0'>
        <h2>How to use</h2>
        <div className='row'>
            <div className='col-12 col-md-6'>
                <p className='mt-5'>
                    We developed <a href='/'>Synthex</a>, an intuitive Python client, so you can start 
                    generating synthetic data in minutes. No registration required, no annoying platforms. 
                    Just code.
                </p>
                <div className='row mt-4'>
                    <div className='col-1'>
                        <p><i className='bi bi-arrow-right'></i></p>
                    </div>
                    <div className='col-11'>
                        <p>Use <a href='/'>Synthex</a>, our powerful Python client for synthetic data 
                        generation</p>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-1'>
                        <p><i className='bi bi-arrow-right'></i></p>
                    </div>
                    <div className='col-11'>
                        <p>Simply install it by running <code>pip install -U synthex</code></p>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-1'>
                        <p><i className='bi bi-arrow-right'></i></p>
                    </div>
                    <div className='col-11'>
                        <p>
                            <a href='/'>Read the docs</a> to understand how to use Synthex to generate 
                            synthetic data for your specific use-case
                        </p>
                    </div>
                </div>
            </div>
            <div className='col-12 col-md-6 ps-md-5 mt-4 mt-md-0'>
                <img src={synthex} alt='synthex' width='100%' />
            </div>
        </div>
    </div>
};

export default HowToUseSection;