import React from 'react';

import './index.scss';


export const VisionAndMissionSection = () => {
    return <div className='vision-and-mission-section mt-4'>
        <h2>Our vision and mission</h2>
        <div className='row mt-5'>
            <div className='col-12 col-md-1 desktop-only'>
                <i className='bi bi-check-lg'></i>
            </div>
            <div className='col-12 col-md-9'>
                <div className='chip mb-4'>Our mission</div>
                <p>
                    Training large AI models requires lots of data. Since acquiring large real-world datasets 
                    is an expensive task, only large organizations can typically afford to train 
                    state-of-the-art models. By developing tools for accurate, safe and GDPR-compliant 
                    synthetic data generation, we enable individuals and organizations of any size to train 
                    AI models, even if they do not have the resources to acquire large real-world datasets,
                    thereby democratizing access to Artificial Intelligence.
                </p>
            </div>
        </div>
        <div className='row mt-5'>
            <div className='col-12 col-md-1 desktop-only'>
                <i className='bi bi-check-lg'></i>
            </div>
            <div className='col-12 col-md-9'>
                <div className='chip mb-4'>Our vision</div>
                <p>
                    We want to bring about a world in which the development of large, state-of-the-art AI
                    models is not exclusively done by huge tech companies. We want anybody who owns a laptop
                    to be able to train their own model, even if they do not have the time or resources to 
                    acquire large datasets. We want to democratize access to Artificial Intelligence by
                    democratizing the access to data.
                </p>
            </div>
        </div>
    </div>
};


export default VisionAndMissionSection;