import React from 'react';

import './index.scss';
import { BlogThumbnail } from '../../../components/BlogThumbnail';
import synthex from '../../../assets/synthex.png';


export const ArticlesSection = () => {

    const thumbnails = [
        {
            id: 1,
            img: synthex,
            date: "November 15, 2024",
            title: "Build a question-answer dataset for ChatBot training using our free Python library",
            description: "In this case-study, we show how to use Synthex, our free Python client, to create a question-answer dataset which can be used, among other things, to train a ChatBot",
            articleId: "question-answer-dataset"
        }
    ]

    return <div className='articles-section'>
        <h2>Tanaos blog</h2>
        <div className='mb-5'>
            <div className='row'>
                {
                    thumbnails.map(( thumbnail ) => {
                        return <div className='col-12 col-md-4 mt-5' key={thumbnail.id}>
                            <BlogThumbnail 
                                img={thumbnail.img}
                                date={thumbnail.date}
                                title={thumbnail.title}
                                description={thumbnail.description}
                            />
                        </div>
                    })
                }
            </div>
        </div>
    </div>
};

export default ArticlesSection;