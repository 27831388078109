import React from 'react';

import './index.scss';


export const HeroSection = () => {
    return <div className='pricing-page-hero-section'>
        <div className='row'>
            <div className='col-12 col-md-8'>
                <h1 className='hero-title'>
                    Start <span className='colored'>for free</span>, pay only if you need more data
                </h1>
                <h2 className='hero-subtitle mt-5'>
                    Find the right plan for you: if you only need limited data, our tools are free to use; 
                    if you need more data, get in touch with us for a customized offer
                </h2>
                <button className='btn btn-primary mt-5'>View pricing plans</button>
            </div>
        </div>
    </div>
};

export default HeroSection;