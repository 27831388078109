import React from 'react';

import './index.scss';


export const WhyTanaosSection = () => {
    return <div id='why-tanaos' className='why-tanaos-section'>
        <div className='row mb-4'>
            <h2 className='mb-5'>Why Tanaos?</h2>
            <div className='col-12 col-md-8'>
                <p>
                    Finding high-quality, private and safe data to train your Machine Learning models is 
                    hard. Tanaos makes it easier by offering tools to generate synthetic data that 
                    is highly realistic, representative and virtually undistinguishable from real data,
                    while maintaining high standards of privacy and anonymity.
                </p>
            </div>
        </div>
        <div className='row mt-5'>
            <div className='col-12 col-md-4 pe-md-5'>
                <div className='icon-container mb-5'>
                    <i className='bi bi-stopwatch'></i>
                </div>
                <h3>Train faster</h3>
                <p className='mt-3'>
                    Tired of waisting time putting together large datasets for model training? Tanaos 
                    gives you all the tools you need to generate large and accurate datasets in minutes,
                    whatever your use-case may be.
                </p>
                <a href="/">Try it now ➔</a>
            </div>
            <div className='col-12 col-md-4 pe-md-5 mt-5 mt-md-0'>
                <div className='icon-container mb-5'>
                    <i className='bi bi-graph-up-arrow'></i>
                </div>
                <h3>Increase model accuracy</h3>
                <p className='mt-3'>
                    AI models' accuracy is heavily dependent on the quality of the training dataset. Increase 
                    your model's accuracy by generating high-quality and plentiful data for your specific 
                    use-case.
                </p>
                <a href="/">Try it now ➔</a>
            </div>
            <div className='col-12 col-md-4 pe-md-5 mt-5 mt-md-0'>
                <div className='icon-container mb-5'>
                    <i className='bi bi-lock-fill'></i>
                </div>
                <h3>Be GDPR-compliant</h3>
                <p className='mt-3'>
                    Worried about whether your model will be legal under EU regulations? We got you covered:
                    the data generated by our tools is private, anonymized and 100% GDPR-compliant.
                </p>
                <a href="/">Try it now ➔</a>
            </div>
        </div>
    </div>
};

export default WhyTanaosSection;