import React from 'react';

import './index.scss';
import { CustomCarousel } from '../../../components/CustomCarousel';
import text_classification from './text_classification.png';


export const CaseStudiesSection = () => {

    const carouselSlides = [
        {
            title: 'Using Synthex to generate a text classification dataset',
            text: 'In this case study we show how to generate a text classification dataset with Synthex, and how the dataset generated by Synthex is undistinguishable from a real-world one.',
            image: text_classification
        },
        {
            title: 'Using Synthex to generate a text classification dataset',
            text: 'In this case study we show how to generate a text classification dataset with Synthex, and how the dataset generated by Synthex is undistinguishable from a real-world one.',
            image: text_classification
        },
        {
            title: 'Using Synthex to generate a text classification dataset',
            text: 'In this case study we show how to generate a text classification dataset with Synthex, and how the dataset generated by Synthex is undistinguishable from a real-world one.',
            image: text_classification
        }
    ]

    return <div className='case-studies-section mt-5 mt-md-0'>
        <div className='row'>
            <div className='col-12 col-md-8'>
                <h2>Case studies</h2>
                <p className='mt-5'>
                    <a href='/'>Synthex</a> is a general-purpose synthetic data generation library, which 
                    can be used to generate a large variety of datasets. Take a look at these case studies 
                    to find out how Synthex can help you generate synthetic data for your specific use-case.
                </p>
            </div>
        </div>
        <CustomCarousel 
            slides={carouselSlides}
        />
    </div>
};

export default CaseStudiesSection;